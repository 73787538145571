import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import GolfTournamentContent from '../components/Events/GolfTournamentContent';

export const GolfTournamentPageTemplate = ({
  hero,
  header,
  subheader,
  goBlurbs,
  links,
  tournamentInfo,
  sponsor
}) => {
  return (
    <div className="flex flex-col w-full bg-s4tk-white">
      <GolfTournamentContent
        hero={hero}
        header={header}
        subheader={subheader}
        goBlurbs={goBlurbs}
        links={links}
        tournamentInfo={tournamentInfo}
        sponsor={sponsor}
      />
    </div>
  );
};

const GolfTournamentPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <GolfTournamentPageTemplate
        hero={frontmatter.hero}
        header={frontmatter.header}
        subheader={frontmatter.subheader}
        goBlurbs={frontmatter.goBlurbs}
        links={frontmatter.tournamentLinks}
        tournamentInfo={frontmatter.tournamentInfo}
        sponsor={frontmatter.sponsor}
      />
    </Layout>
  );
};

export default GolfTournamentPage;

export const golfTournamentPageQuery = graphql`
  query GolfTournamentPageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "golf-tournament-page" } }
    ) {
      frontmatter {
        hero {
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          text
        }
        header
        subheader
        goBlurbs
        tournamentLinks {
          title
          icon {
            prefix
            title
          }
          url
        }
        tournamentInfo {
          title
          items {
            text
          }
        }
        sponsor {
          blurb
          opportunities {
            item {
              title
              description
              icon {
                prefix
                title
              }
              url
            }
            opblurbs
          }
        }
      }
    }
  }
`;
