import React from 'react';
import Hero from '../Hero';
import Section, { SubSection } from '../Section';
import SmallLink from '../SmallLink';

export const GolfTournamentContent = ({
  hero,
  header,
  subheader,
  goBlurbs,
  links,
  tournamentInfo,
  sponsor
}) => {
  const colors = [
    'bg-s4tk-blue',
    'bg-s4tk-orange',
    'bg-s4tk-green',
    'bg-s4tk-darkBlue'
  ];
  return (
    <>
      <Hero images={hero.images} title={hero.text} />
      <Section>
        <p className="mb-4 text-4xl font-bold text-left sm:text-justify font-baloo text-s4tk-blue">
          {header}
        </p>
        <p className="text-3xl text-left sm:text-justify font-baloo text-s4tk-blue">
          {subheader}
        </p>
        <SubSection>
          <div className="flex flex-col w-full space-y-4">
            {goBlurbs.map((item, index) => (
              <p
                key={index}
                className="text-2xl text-left text-black font-baloo sm:text-justify"
              >
                {item}
              </p>
            ))}
          </div>
        </SubSection>
        <SubSection>
          <div className="flex flex-row flex-wrap justify-around w-full">
            {links.map((item, index) => (
              <SmallLink
                key={index}
                internal={item.url.charAt(0) === '#'}
                item={item}
              />
            ))}
          </div>
        </SubSection>
        <div className="flex flex-col items-start justify-center w-full py-16 space-y-16">
          {tournamentInfo.map((item, index) => (
            <div className="flex flex-col w-full">
              <p className="mb-2 text-3xl font-thin font-baloo text-s4tk-blue">
                {item.title}
              </p>
              <ul className="px-0 space-y-2 list-disc list-inside md:px-4 lg:px-8">
                {item.items.map((item, subIndex) => (
                  <li
                    key={subIndex}
                    className="text-2xl text-left text-black font-baloo sm:text-justify"
                  >
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <SubSection>
          <p
            id="sponsor"
            className="mb-12 text-4xl font-bold text-left font-baloo text-s4tk-blue sm:text-justify"
          >
            {sponsor.blurb}
          </p>
          <div className="flex flex-col w-full space-y-8">
            {sponsor.opportunities.map((item, index) => (
              <div
                key={index}
                className="flex flex-col items-start w-full md:flex-row md:justify-center md:space-x-8 md:items-center"
              >
                <div className="self-center w-full mb-4 md:w-auto">
                  <SmallLink
                    color={colors[index % colors.length]}
                    item={item.item}
                  />
                </div>
                <ul className="w-full px-0 space-y-2 list-disc list-inside md:px-4 lg:px-8 md:w-114">
                  {item.opblurbs.map((blurb, index) => (
                    <li
                      key={index}
                      className="text-xl text-left text-black font-baloo"
                    >
                      {blurb}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </SubSection>
      </Section>
    </>
  );
};

export default GolfTournamentContent;
